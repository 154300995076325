import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
ReactDOM.render(
  <React.StrictMode>
    <p>HELLO FROM REACT!</p>
  </React.StrictMode>,
  document.getElementById('root')
)
reportWebVitals()
